<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="title" type="back" :settings="false" />
    </template>

    <div class="settings-container">
      <div class="container sett-content">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation('LBL_COSIGNEE_INFORMATION') }}</f7-list-item>

          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            :label="$t.getTranslation('LBL_LAST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.FirstName"
            name="FirstName"
            :required="validationRules?.FirstName?.required"
            :minlength="validationRules?.FirstName?.minimumLength"
            :maxlength="validationRules?.UserName?.maximumLength"
            :label="$t.getTranslation('LBL_FIRST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Email"
            name="Email"
            :required="false"
            :minlength="validationRules?.Email?.minimumLength"
            :maxlength="validationRules?.Email?.maximumLength"
            :label="$t.getTranslation('LBL_EMAIL')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="email"
            validate
            clear-button
          >
          </f7-list-input>

          <!-- <f7-list-input
            v-model:value="formData.MobileCode"
            name="MobileCode"
            :required="true"
            :minlength="validationRules?.MobileCode?.minimumLength"
            :maxlength="validationRules?.MobileCode?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            disabled
          >
          </f7-list-input> -->

          <f7-list-input
            v-model:value="formData.MobileNumber"
            name="MobileNumber"
            :required="true"
            :minlength="validationRules?.MobileNumber?.minimumLength"
            :maxlength="validationRules?.MobileNumber?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_NUMBER')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-item divider>{{ $t.getTranslation('LBL_ADDRESS_INFORMATION') }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.Country"
            name="Province"
            :required="validationRules?.CountryCode?.required"
            :minlength="validationRules?.CountryCode?.minimumLength"
            :maxlength="validationRules?.Province?.maximumLength"
            :label="$t.getTranslation('LBL_COUNTRY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            disabled="true"
          >
          </f7-list-input>

          <!-- <f7-list-input
            v-model:value="formData.Province"
            name="Province"
            :required="validationRules?.Province?.required"
            :minlength="validationRules?.Province?.minimumLength"
            :maxlength="validationRules?.Province?.maximumLength"
            :label="$t.getTranslation('LBL_PROVINCE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input> -->

          <f7-list-input v-model:value="formData.Province" name="Province" :label="$t.getTranslation('LBL_PROVINCE')" :placeholder="$t.getTranslation('LBL_TYPE_HERE')" floating-label outline type="select">
            <template v-if="provinces && provinces.length > 0">
              <option v-for="p in provinces" :key="'p_' + p.Province" :value="p.Province">{{ p.Province }}</option>
            </template>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.City"
            name="City"
            :required="validationRules?.City?.required"
            :minlength="validationRules?.City?.minimumLength"
            :maxlength="validationRules?.City?.maximumLength"
            :label="$t.getTranslation('LBL_CITY')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.District"
            name="District"
            :required="validationRules?.District?.required"
            :minlength="validationRules?.District?.minimumLength"
            :maxlength="validationRules?.District?.maximumLength"
            :label="$t.getTranslation('LBL_DISTRICT')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.PrimaryAddress"
            name="PrimaryAddress"
            :required="validationRules?.PrimaryAddress?.required"
            :minlength="validationRules?.PrimaryAddress?.minimumLength"
            :maxlength="validationRules?.PrimaryAddress?.maximumLength"
            :label="$t.getTranslation('LBL_ADDRESS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <!--  <f7-list-input
            v-model:value="formData.PostalCode"
            name="PostalCode"
            :required="false"
            :minlength="validationRules?.PostalCode?.minimumLength"
            :maxlength="validationRules?.PostalCode?.maximumLength"
            :label="$t.getTranslation('LBL_ZIP')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input> -->
          <f7-list-item class="item-default-address">
            <span>{{ $t.getTranslation('LBL_SET_DEFAULT_ADDRESS') }}</span>
            <f7-toggle v-model:checked="formData.IsDefault"></f7-toggle>
          </f7-list-item>
          <f7-list-item>
            <f7-button large fill class="btn-submit" @click="submit">{{ $t.getTranslation('LBL_SUBMIT') }}</f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, inject, defineAsyncComponent } from 'vue'
import { post, get } from '@/utils/axios'
import { useStore } from '@/store'
import { helpers } from '../../utils/helpers'

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProfileNavigationComponent.vue'))

export default defineComponent({
  name: 'AddressSettings',
  components: {
    ProfileNavigationComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')

    const store = useStore()

    let title = ref($t.getTranslation('LBL_ADD_ADDRESS'))

    store.dispatch('config/fetchData', {
      params: { countryList: 1, mobileCodeList: 1, provinces: 1 }
    })

    let countryList = computed(() => store.getters['config/getData']?.countryList)
    let mobileCodeList = computed(() => store.getters['config/getData']?.mobileCodeList)
    let provinces = computed(() => store.getters['config/getData']?.provinces)

    let configData = computed(() => store.getters['config/getData'])
    // const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])

    const formData = reactive({
      UserAddressKey: null,
      FirstName: '',
      LastName: '',
      Email: '',
      MobileCode: '+86',
      MobileNumber: '',
      CountryCode: 'CN',
      District: '',
      City: '',
      PrimaryAddress: '',
      PostalCode: '',
      IsDefault: false,
      Country: '中国'
    })

    const submit = async () => {
      formData.MobileNumber = formData?.MobileNumber.replace('+86', '');
      formData.MobileNumber = formData?.MobileNumber.replace('+852', '');
      if(formData?.MobileNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && ! (formData?.MobileNumber.match(/0{5,}/)) ){
        helpers.createNotification({
            type: "error",
            title: $t.getTranslation("LBL_ERROR"),
            message: $t.getTranslation("ERR_INVALID_MOBILE"),
          });
        return;
      }
 
      let ret = await post('/user/address', formData)
      if (!ret) return
      helpers.goBack()
      //props.f7router.navigate({ name: "cartPage", force: true });
      //props.f7router.navigate({ name: "orderCheckoutPage", force: true });
    }

    onMounted(async () => {
      if (!props.f7route.query.key) return
      
      let ret = await get('/user/address', { key: props.f7route.query.key })
      if (!ret) return props.f7router.navigate({ force: true })
      formData.CountryCode = 'CN'
      formData.Country = '中国'
      formData.UserAddressKey = ret.Address.UserAddressKey
      formData.FirstName = ret.Address.FirstName
      formData.LastName = ret.Address.LastName
      formData.Email = ret.Address.Email

      //formData.MobileCode = ret.Address.MobileCode;
      //formData.MobileCode = configData.value.mobileCodeDefault;

      if(!formData?.MobileNumber.indexOf('+') > -1){
        formData.MobileNumber = `${formData.MobileCode}${ret.Address.MobileNumber}`;
      }
      
      formData.CountryCode = ret.Address.CountryCode
      formData.Province = ret.Address.Province
      formData.IdNumber = ret.Address.IdNumber
      formData.District = ret.Address.District
      formData.City = ret.Address.City
      formData.PrimaryAddress = ret.Address.PrimaryAddress
      formData.PostalCode = ret.Address.PostalCode
      formData.IsDefault = ret.Address.IsDefault

      title.value = $t.getTranslation('LBL_UPDATE_ADDRESS')
    })

    return {
      submit,
      title,
      formData,
      countryList,
      mobileCodeList,
      provinces
    }
  }
})
</script>

<style>
.btn-submit {
  width: 100%;
  margin: 0px auto 0px;
}
.item-default-address {
  margin-top: 10px;
}
.item-default-address .item-inner span {
  font-size: 14px;
}
.settings-container .sett-content {
  width: calc(100% - 0px) !important;
}
</style>
